@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.signupContent {
  @media (--viewportMedium) {
    max-height: 500px;
    overflow-y: auto;
    padding-right: 20px;
    
    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
      background: #888; 
    }
  }
}
.formRowContent {
  & > h4 {
    margin-top: 0;
    color: var(--colorBlack);
    font-size: 20px;
    font-weight: var(--fontWeightMedium);
  }
  & .userDetailsHeading{
    margin-bottom: 20px;
  }
}
.formRowBox{
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  & .formFld{
    width: calc(50% - 9px);
  }
}
.formRow {
  display: flex;
  &.fullWidth {
    flex-direction: column;

    & .formFld {
      width: 100%;
      margin-right: 0;
    }
  }
  & .formFld {
    width: calc(100% / 2 - 12px);
    margin-bottom: 24px;
    &:not(:last-child) {
      margin-right: 24px;
    }
    & > span {
      & > label {
        padding: 0;
        & > span {
          padding: 0;
        }
      }
    }
  }
}
& :global(.PhoneInput) {
  display: flex;
}
& :global(.PhoneInputCountry) {
  position: relative;
  width: calc(100% / 2 - 12px);
  margin-right: 24px;
}
& :global(.PhoneInputCountrySelect) {
  padding-left: 60px;
}
& :global(.PhoneInputCountryIcon) {
  width: 30px;
  position: absolute;
  top: calc(48px / 4);
  left: 16px;
}
& :global(.PhoneInputInput) {
  width: calc(100% / 2 - 12px);
}
.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
}

.locationAutocompleteInput {
}

.locationAutocompleteInputIcon {
  display: none;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}
.clientOption{
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}